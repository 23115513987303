import { Card, Radio, Button, Alert, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import Auth from './Auth';


class Listing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventHeaders: [],
            msg: "hello",
            listing: [],
            delMode: false,
            popVisible: false,
        }
        this.handleList = this.handleList.bind(this);
    }

    async handleDelete(instanceID, e) {
        console.log(instanceID);
        const opts = { method: 'DELETE', credentials: 'include' }
        const resp = await fetch('/api/delete/' + instanceID, opts)
        if (resp.ok) {
            this.setState({
                eventHeader: "Delete request submitted"
            });
        } else {
            this.setState({
                eventHeader: "Delete request errored"
            });
        }
    }

    async handleRegister(instanceID, e) {
        console.log(instanceID);
        const opts = {method: 'PUT', credentials: 'include'};
        const resp = await fetch(
            '/api/registry/dns/' + instanceID, 
            opts,
        );
        if (resp.ok) {
            this.setState({
                eventHeader: "Registry request submitted"
            });
            this.unshowPop();
        } else {
            this.setState({
                eventHeader: "Registry request errored"
            })
        };
    }

    async handleList() {
        if (!Auth.isAuthenticated()) {
            console.log("Unauthenticated");
            return
        }
 
        const resp = await fetch(
            "/api/list/", {
            credentials: 'include',
        });
        if (!resp.ok) {
            console.log("Unauthenticated");
        }
        else {
            const data = await resp.json();
            console.log(data);
            this.setState({
                msg: data,
                listing: data.listing,
                delMode: true,
            });
        }
    }

    async showPop() {
        console.log("Show popup");
        this.setState({popVisible: true});
    }

    async unshowPop() {
        this.setState({popVisible: false});
    }

    componentDidMount() {
        this.handleList()
    }

    render() {
        const items = this.state.listing.map((item) =>
            <Card key={item.instanceID}>
                <p>{item.instanceID}</p>
                <p>{item.publicIP}</p>
                <Popconfirm
                    title="Confirm?"
                    visible={this.state.popVisible}
                    onConfirm={(e) => this.handleRegister(item.instanceID, e)}
                    okText="Yes"
                    onCancel={() => this.unshowPop()}
                >
                <Button href="#" onClick={(e) => this.showPop()}>Register DNS</Button>
                </Popconfirm>
                {this.state.delMode && <Button href="#" onClick={(e) => this.handleDelete(item.instanceID, e)}>Delete</Button>}
            </Card>
        );
        return (
            <>
                {items}
            </>
        );
    }
}

export default Listing;