import './App.css';
import Auth from './Auth.js'
import Listing from './Listing.js';
import { Layout, Menu } from 'antd';
import { Card, Radio, Button, Alert } from 'antd';
import React, { useEffect, useState } from 'react';

const { Header, Content, Footer } = Layout;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: "listing",
            msg: "hello",
            listing: [],
            delMode: false,
            events: [],
        }
        this.handleStart = this.handleStart.bind(this);
    }

    handleClick = e => {
        this.setState({ current: e.key })
    };


    async handleStart() {
        const opts = {
            method: 'POST',
            header: { 'Content-Type': 'application/json' },
        };
        const resp = await fetch('/api/start', opts);
        if (resp.ok) {
            this.setState({
                msg: "Submitted",
                listing: [],
                delMode: false,
                events: [{ message: "Submitted", type: "success" }]
            });
        } else {
            this.setState({
                msg: "Errored",
                listing: [],
                delMode: false,
                events: [{ message: "Failed", type: "error" }]
            });
        }
    }
    render() {
        let currentContent;
        if (this.state.current == 'listing') {
            currentContent = <Listing listing={this.state.listing} delete={this.state.delMode} />
        } else if (this.state.current == 'start') {
            const eventAlerts = this.state.events.map((each) =>
                <Alert
                    message={each.message}
                    type={each.type}
                    closable
                />
            )
            currentContent =
                <div>
                    {eventAlerts}
                    <Radio>default</Radio>
                    <Button onClick={this.handleStart}>Start</Button>
                </div>
        }

        const content =
            <Content>
                <div className="site-layout-content">
                    <p>hi</p>
                    {currentContent}
                </div>
            </Content>;

        const { current } = this.state;
        return (
            <>
                <Layout className="layout">
                    <Header>
                        <Menu theme="dark" mode="horizontal" onClick={this.handleClick} selectedKeys={[current]}>
                            <Menu.Item key="listing" >Listing</Menu.Item>
                            <Menu.Item key="start">Start</Menu.Item>

                            <Auth/>
                        </Menu>
                    </Header>
                </Layout>

                <Layout>
                    {content}
                </Layout>
            </>
        );
    }
}

export default App;
