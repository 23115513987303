import React from "react";
import GoogleLogin from "react-google-login";
import { Menu, Button } from "antd";
import { GoogleOutlined } from '@ant-design/icons';

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
    }

    static authenticateUser(token) {
        localStorage.setItem('ns-session', token);
    }
    static isAuthenticated() {
        return localStorage.getItem('ns-session') !== null;
    }
    static unauthenticateUser() {
        localStorage.removeItem('ns-session')
    }

    async handleLogin(response) {
        console.log(response);
        const resp = await fetch("/api/login/auth", {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify({
                id_token: response.tokenId
            })
        });
        console.log(resp);
        if (!resp.ok) {
            Auth.unauthenticateUser();
        } else {
            console.log("User logged in")
            const j = await resp.json();
            // console.log(j);
            console.log(j.token);
            Auth.authenticateUser(j.token);
            window.location.reload();
        }
    };

    async handleLogout() {
        Auth.unauthenticateUser();
        window.location.reload();
    };

    render() {
        if (Auth.isAuthenticated() == true) {
            return (
                <Menu theme="dark" mode="horizontal" style={{ float: 'right' }} >
                    <Menu.Item key="user">
                        Hello
                    </Menu.Item>
                    <Menu.Item key="logout" onClick={this.handleLogout}>
                        logout
                    </Menu.Item>
                </Menu>
            )
        }
        else {
            return (
                <Menu theme="dark" mode="horizontal" style={{ float: 'right' }}>
                    <GoogleLogin
                        render={renderProps => (
                            <Button
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                                icon={<GoogleOutlined />}
                            >
                                Google Login
                            </Button>
                        )}
                        clientId="33953050227-m9hj11cm56e52to9i2fcf57i4siudoau.apps.googleusercontent.com"
                        buttonText="Log in with Google"
                        onSuccess={this.handleLogin}
                        onFailure={this.handleLogin}
                        cookiePolicy={'single_host_origin'}
                    />
                </Menu>
            )
        }
    };
}

export default Auth;